var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ml-3",attrs:{"data-cy":"552","fluid":""}},[(_vm.canUserAddAccess)?_c('div',{staticClass:"d-block mb-10",attrs:{"data-cy":"577"}},[_c('v-btn',{ref:"button_add_access",attrs:{"data-cy":"578","color":"primary","large":""},on:{"click":function($event){_vm.showUpdateAccessDialog = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"data-cy":"579","small":""}},[_vm._v("mdi-plus")]),_c('span',{attrs:{"data-cy":"580"}},[_vm._v(_vm._s(_vm.$t("673")))])],1)],1):_vm._e(),_c('div',{staticClass:"d-block text-h6 md-4",attrs:{"data-cy":"553"}},[_vm._v(_vm._s(_vm.$t("668")))]),_c('v-data-table',{ref:"table_users_with_access",staticClass:"mt-4",attrs:{"data-cy":"554","item-key":"id","items":_vm.usersWithAccess,"items-per-page":-1,"headers":_vm.headersForUsersWithAccessTable,"loading":_vm.isLoadingAccesses,"fixed-header":"","multi-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"data-cy":"555","size":"38","color":"primary lighten-5"}},[_c('span',{staticClass:"primary--text text--darken-1 font-weight-bold",attrs:{"data-cy":"556"}},[_vm._v(_vm._s(_vm._f("initials")(item.name)))])]),_c('span',{staticClass:"ml-2 info--text",attrs:{"data-cy":"557"}},[_vm._v(_vm._s(item.name))]),(item.disabledButNotDeleted)?_c('span',{staticClass:"ml-1 info--text",attrs:{"data-cy":"2221"}},[_vm._v(_vm._s(_vm.$t("1131")))]):_vm._e()]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._l((item.roles),function(role){return [(role.domain)?_c('v-tooltip',{attrs:{"data-cy":"558","color":"info darken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"data-cy":"559","color":"info lighten-3","text-color":"primary darken-4","label":""}},on),[_vm._v(_vm._s(role.name))])]}}],null,true)},[_c('span',{attrs:{"data-cy":"2223"}},[_vm._v(_vm._s(_vm.$t("667", {domain: role.domain})))])]):_c('v-chip',{staticClass:"mr-2",attrs:{"data-cy":"561","text-color":"primary darken-4","color":"primary lighten-5","label":""}},[_vm._v(_vm._s(role.name))])]})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [(_vm.canUserAddAccess || _vm.canUserRemoveAccess)?_c('v-btn',{ref:("button_edit_user_access_" + (item.id)),attrs:{"data-cy":"612","color":"secondary","text":""},on:{"click":function($event){return _vm.handleEditAccess(true, item)}}},[_vm._v(_vm._s(_vm.$t("685")))]):_vm._e()]}}])}),_c('div',{staticClass:"d-block text-h6 md-4 mt-15",attrs:{"data-cy":"562"}},[_vm._v(_vm._s(_vm.$t("671")))]),_c('v-data-table',{ref:"table_groups_with_access",staticClass:"mt-4",attrs:{"data-cy":"563","item-key":"id","items":_vm.groupsWithAccess,"items-per-page":-1,"headers":_vm.headersForGroupsWithAccessTable,"loading":_vm.isLoadingAccesses,"expanded":_vm.expandedGroups,"fixed-header":"","multi-sort":"","hide-default-footer":"","show-expand":""},on:{"update:expanded":function($event){_vm.expandedGroups=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"data-cy":"564","size":"38","color":"primary lighten-5"}},[_c('v-icon',{staticClass:"primary--text text--darken-1",attrs:{"data-cy":"565","medium":""}},[_vm._v("mdi-group")])],1),_c('span',{staticClass:"ml-2 info--text",attrs:{"data-cy":"566"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._l((item.roles),function(role){return [(role.domain)?_c('v-tooltip',{attrs:{"data-cy":"567","color":"info darken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"data-cy":"568","color":"info lighten-3","text-color":"primary darken-4","label":""}},on),[_vm._v(_vm._s(role.name))])]}}],null,true)},[_c('span',{attrs:{"data-cy":"569"}},[_vm._v(_vm._s(_vm.$t("667", {domain: role.domain})))])]):_c('v-chip',{staticClass:"mr-2",attrs:{"data-cy":"570","color":"primary lighten-5","text-color":"primary darken-4","label":""}},[_vm._v(_vm._s(role.name))])]})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [(_vm.canUserAddAccess || _vm.canUserRemoveAccess)?_c('v-btn',{ref:("button_edit_group_access_" + (item.id)),attrs:{"data-cy":"611","color":"secondary","text":""},on:{"click":function($event){return _vm.handleEditAccess(false, item)}}},[_vm._v(_vm._s(_vm.$t("685")))]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.users)?_c('td',{staticClass:"pa-0",style:(("height: " + ((item.users.length) * 48) + "px")),attrs:{"data-cy":"571","colspan":headers.length}},[_c('v-simple-table',{attrs:{"data-cy":"572"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',{attrs:{"data-cy":"573"}},_vm._l((item.users),function(user){return _c('tr',{key:user.id,attrs:{"data-cy":"574"}},[_c('td',{staticClass:"pl-15",attrs:{"data-cy":"575"}},[_c('v-avatar',{attrs:{"data-cy":"576","size":"38","color":"primary lighten-5"}},[_c('span',{staticClass:"primary--text text--darken-1 font-weight-bold",attrs:{"data-cy":"578"}},[_vm._v(_vm._s(_vm._f("initials")(user.name)))])]),_c('span',{staticClass:"ml-2 info--text",attrs:{"data-cy":"560"}},[_vm._v(_vm._s(user.name))]),(user.disabledButNotDeleted)?_c('span',{staticClass:"ml-1 info--text",attrs:{"data-cy":"2224"}},[_vm._v(_vm._s(_vm.$t("1131")))]):_vm._e()],1)])}),0)]},proxy:true}],null,true)})],1):_vm._e()]}}]),model:{value:(_vm.expandedGroups),callback:function ($$v) {_vm.expandedGroups=$$v},expression:"expandedGroups"}}),(_vm.showUpdateAccessDialog)?_c('v-dialog',{ref:"dialog_update_access",attrs:{"data-cy":"581","max-width":"700"},model:{value:(_vm.showUpdateAccessDialog),callback:function ($$v) {_vm.showUpdateAccessDialog=$$v},expression:"showUpdateAccessDialog"}},[_c('themis-update-access',{ref:"themis-issue-update-access",attrs:{"p-issue":_vm.issue,"p-users-without-access":_vm.usersWithoutAccess,"p-groups-without-access":_vm.groupsWithoutAccess,"p-users-with-access":_vm.usersWithAccess,"p-groups-with-access":_vm.groupsWithAccess,"p-roles":_vm.roles,"p-selected-user":_vm.selectedUserForEditing,"p-selected-group":_vm.selectedGroupForEditing,"p-can-user-remove-access":_vm.canUserRemoveAccess,"p-can-user-add-access":_vm.canUserAddAccess},on:{"addAccess":_vm.addAccess,"removeAccess":_vm.removeAccess,"close":_vm.handleCloseUpdateAccess,"accessAdded":_vm.handleAccessAdded,"accessUpdated":_vm.handleAccessUpdated}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }